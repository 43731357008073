import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

const Button = React.forwardRef(function Button(
  { children, ...props },
  forwardedRef
) {
  return (
    <Container ref={forwardedRef} mt={4} {...props}>
      <div>
        <i className="material-icons">keyboard_arrow_right</i>
        <span>{children}</span>
      </div>
    </Container>
  );
});

const Container = styled.div`
    font-size: 16px;
    /* border-bottom: 2px solid ${props => props.theme.colors.high}; */
    font-weight: 600;
    line-height: 30px;

    display: inline-block;

    color: white !important;
    text-decoration: none !important;

    font-family: 'Cairo', sans-serif;

    ${space}
    
    > div {
        display: flex;

        > i {
            line-height: 1.3;
            font-size: 1.5em;

            padding-right: .5em;

            color: ${props => props.theme.colors.high};
        }
    }

`;

export default Button;
