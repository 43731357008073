import styled from "styled-components";
import { space, layout, position } from "styled-system";

const Img = styled.img`
    display: block;
    ${position}
    ${layout}
    ${space}
`;

export default Img;
