import React from "react";

import styled from "styled-components";

import { space } from "styled-system";

import Box from "./box";
import Img from "./img";

import { Subtitle, BodyText } from "./text";

function Story() {
  return (
    <Box full position={"relative"} paddingTop={"175vw"} my={4}>
      <Box
        position={"absolute"}
        zIndex={3}
        bottom={0}
        left={0}
        right={0}
        padding={30}
      >
        <div>
          <Subtitle my={3}>Pietro Gorgazzini</Subtitle>

          <BodyText>
            Le nostre operations, ad oggi, coprono tutto il territorio nazionale
            e la regione balcanica,
          </BodyText>
        </div>

        <Nav mt={4}>
          <NavBtn progress={1} />
          <NavBtn progress={0.5} />
        </Nav>
      </Box>

      <Overlay />

      <Img
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        src="https://picsum.photos/400/700?random&xs"
      />
    </Box>
  );
}

const Overlay = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  z-index: 2;
`;

const Nav = styled.div`
  display: flex;

  > * + * {
    margin-left: 5px;
  }

  ${space}
`;

const NavBtn = styled.div`
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 100% 1 1;

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: white;

    transform: scaleX(${props => props.progress});
    transform-origin: left top;
  }
`;

export default Story;
