import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

import Box from "./box";

const Slider = styled(Box)`
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const SliderWrapper = styled(Box)`
  display: block;
  word-spacing: 0;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  padding: 0 35px;
`;

export const SliderItem = styled.div`
  word-spacing: initial;
  white-space: initial;
  width: calc(100vw - 86px);
  margin-right: 16px;

  display: inline-block;
  vertical-align: top;
  ${space}
`;

export default props => {
  return (
    <Box full>
      <Slider>
        <SliderWrapper>{props.children}</SliderWrapper>
      </Slider>
    </Box>
  );
};
