import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Box from "../../components/box";
import Button from "../../components/button";
import Story from "../../components/story";
import Slider, { SliderItem } from "../../components/slider";
import { Title, Subtitle, SmallTitle, BodyText } from "../../components/text";

import image from "../../images/pr5.jpg";

import useEntryAnimation from "../../utility/useEntryAnimation";

const IndexPage = () => {
  const $container = React.useRef();
  const [animateIn] = useEntryAnimation($container);

  React.useEffect(() => {
    animateIn();
  }, []);

  return (
    <Layout bg={image}>
      <SEO title="Home" />

      <div ref={$container}>
        <Box my={5} mt={6}>
          <SmallTitle data-reveal>Applications</SmallTitle>
          <Title data-reveal mb={5}>
            Mining
          </Title>

          <BodyText data-reveal="lines">
            I siti di estrazione a cielo aperto rappresentano spesso per la
            topografia tradizionale una sfida in termini di monitoraggio e
            gestione. Siamo in grado di offrire aggiornamenti topografici
            completi di computo metrico dei cumuli in 24h, con sezioni di
            controllo e tavole in .dwg scaricabili con un click da Primis Live®.
          </BodyText>

          <Button data-reveal as={Link} to="/contacts">
            Contact Us
          </Button>
        </Box>

        <Box>
          <SmallTitle data-reveal>Services</SmallTitle>

          <Slider>
            <SliderItem mr={3} data-reveal>
              <Subtitle fontSize={3}>Survey</Subtitle>
              <BodyText fontSize={1}>
                I siti di estrazione a cielo aperto rappresentano spesso per la
                topografia tradizionale una sfida in termini di monitoraggio e
                gestione.{" "}
              </BodyText>
            </SliderItem>

            <SliderItem mr={3} data-reveal>
              <Subtitle fontSize={3}>Real Time</Subtitle>
              <BodyText fontSize={1}>
                I siti di estrazione a cielo aperto rappresentano spesso per la
                topografia tradizionale una sfida in termini di monitoraggio e
                gestione.{" "}
              </BodyText>
            </SliderItem>

            <SliderItem mr={3}>
              <Subtitle fontSize={3}>Survey</Subtitle>
              <BodyText fontSize={1}>Lorem ipsum dolor sit amet</BodyText>
            </SliderItem>
          </Slider>
        </Box>

        <Box my={5}>
          <SmallTitle>Lorem</SmallTitle>
          <Story />
        </Box>
      </div>
    </Layout>
  );
};

export default IndexPage;
